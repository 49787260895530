.zt-statcard {
    display: block;
    flex-direction: column;
    justify-content: space-between;
    // min-width: 352px;
    margin: 0;
    
    .zt-statcard__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // margin-top: -8px;
        
        
        .zt-statcard__stat {
            font-size: 1.625rem;
            font-weight: 500;
            align-self: center;
            color: kt-base-color(label, 5);
        }
        

        .zt-statcard__pct-container {
            align-items: center;

            .zt-statcard__pct {
                display: inline-block;
                font-weight: 500;
                span {
                    font-size: 0.875rem;
                }    
                color: kt-base-color(label, 3);
                
            }
        }
    }

    .zt-statcard__title {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0;
        color: kt-base-color(label, 4);
    }

    .zt-statcard__description {
        font-size: 0.75rem;
        font-weight: 400;
        display: inline-block;
        color: kt-base-color(label, 3);
        margin-bottom: 16px;
    }

    .zt-statcard__footer {
        font-size: 1.875rem;
        align-self: flex-end;

        .MuiSvgIcon-root {
            font-size: 2rem;
        }
    }
}
