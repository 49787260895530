:root {
  --amazonOrange: #007BA7;
  --lightAmazonOrange: #4297b9;
  --darkAmazonOrange: #005c7d;
  --squidInk: #232F3E;
  --lightSquidInk: #31465F;
  --deepSquidInk: #152939;
  --grey: #828282;
  --lightGrey: #C4C4C4;
  --silver: #E1E4EA;
  --darkBlue: #31465F;
  --red: #DD3F5B;
  --white: #FFFFFF;
  --light-blue: #00a1c9;

  --button-color: var(--white);
  --button-background-color: var(--amazonOrange);
  --button-click: var(--darkAmazonOrange);
  --link-color: var(--amazonOrange);
  --form-color: var(--white);
  --input-color: var(--deepSquidInk);
  --input-background-color: var(--white);

  --font-family: "Amazon Ember","Helvetica Neue Light","Helvetica Neue","Helvetica" ,"Arial","sans-serif";
  --body-background: #F8F4F4;

  /** This color is used for disabled button bg in login screens **/
  --ion-color-primary-tint: var(--lightAmazonOrange);
}

.amplify-form-container {
  text-align: center;
  margin-top: 20px;
  margin: 5% auto 50px;
}

.amplify-form-section {
  position: relative;
  margin-bottom: 20px;
  background-color: var(--form-color);
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  box-sizing: border-box;
}

.amplify-section-header {
  color: var(--deepSquidInk);
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
}

.amplify-section-header-content {
}

.amplify-section-body {
  margin-bottom: 30px;
}

.amplify-section-body input {
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  color: var(--input-color);
  background-color: var(--input-background-color);
  background-image: none;
  border: 1px solid var(--lightGrey);
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;

}

.amplify-section-body .amplify-input-label {
  color: var(--input-color);
  font-size: 14px;
  margin-bottom: 8px;
}

.amplify-section-footer {
  font-size: 14px;
  color: var(--grey);
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.amplify-section-footer .sectionFooterPrimaryContent {
  margin-left: auto;
}

.amplify-section-footer .sectionFooterPrimaryContent .amplify-button {
  min-width: 153px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  color: var(--button-color);
  background-color: var(--button-background-color);
  border-color: #ccc;
  text-transform: uppercase;
  padding: 14px 0;
  letter-spacing: 1.1px;
  border: none;
}

.amplify-section-footer .sectionFooterPrimaryContent .amplify-button:active {
  opacity: 1;
  background-color: var(--button-click);
}

.amplify-section-footer .sectionFooterPrimaryContent .amplify-button:hover {
  opacity: 0.8;
}

.amplify-section-footer .sectionFooterPrimaryContent .amplify-button:disabled {
  opacity: 1;
  cursor: auto;
  background-color: var(--ion-color-primary-tint);
}

.amplify-section-footer .sectionFooterSecondaryContent {
  margin-right: auto;
  align-self: center;
}

.amplify-section-footer .sectionFooterSecondaryContent .amplify-a {
}

@media only screen and (max-width: 599px) {
  .amplify-section-footer {
    flex-wrap: wrap;
  }

  .sectionFooterPrimaryContent {
    width: 100%;
    margin-bottom: 32px;
  }

  .sectionFooterPrimaryContent > button {
    width: 100%;
  }

  .sectionFooterSecondaryContent {
    text-align: center;
    flex: 0 0 100%;
  }

  .amplify-section-body input {
    font-size: 16px;
  }
}
