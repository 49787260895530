/*
TODO_MAYBE will want to generate this somehow from theme if we go full CSS-in-JS
react-dates can use some sort of 'generic' airbnb library for plugging in CSS-in-JSS solutions
BUT it only supports a few of the last-gen CSS-in-JSS libraries
*/

/* Calender */
.CalendarDay__selected_span {
  background: #99CADC;
  border: 1px double #3395B9;
  color: #fff
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #3395B9;
  border: 1px double #3395B9;
  color: #fff
}
.CalendarDay__selected,
.CalendarDay__selected:hover {
  background: #007BA7;
  border: 1px double #007BA7;
  color: #fff
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #99CADC;
  border: 1px double #3395B9;
  color: #fff
}

/* Base Controls */
.DateRangePicker {
  cursor: pointer;
}
.DateInput_input {
  background: none;
  cursor: pointer;
}
.DateInput_input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FF0038; 
}
.DateInput_input::-ms-input-placeholder { /* Microsoft Edge */
  color: #FF0038;
  opacity: 1; /* Firefox */
}
.DateInput {
  background: none;
}
.DateRangePickerInput_calendarIcon {
  line-height: 1.125rem;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0px;
}
.DateRangePicker_picker {
  z-index: 100; /* (qp) Had to add this to get picker over the top of the material-table column headings */
}

/* VARIANT - outlined */
.DRP_outlined .DateInput {
  width: 104px;
}
.DRP_outlined .DateInput_input {
  color: #007BA7;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.00625rem;
  line-height: 1.125rem;
}
/* .DRP_outlined .DateRangePickerInput_arrow_svg {
  fill: #007BA7;
} */
.DRP_outlined .MuiSvgIcon-root {
  fill: #007BA7;
}
/* .DRP_outlined .DateRangePickerInput_calendarIcon_svg {
  fill: #007BA7;
} */
.DRP_outlined .DateRangePickerInput__withBorder {
  border-radius: 8px;
  border: 1px solid rgba(0, 123, 167, 0.5);
  height: 40px;
}
.DRP_outlined .DateRangePickerInput__withBorder:hover {
  /* background-color: #3395B908; */
  border: 1px solid #007BA7;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.DRP_outlined .DateRangePickerInput__withBorder:hover .MuiSvgIcon-root {
  fill: #007BA7;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.DRP_outlined .DateRangePickerInput__withBorder:hover .DateInput_input {
  color: #007BA7;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.DRP_outlined .DateInput_input__focused {
  border-bottom: 2px solid #007BA7;
  /* height: 40px; */
}

/* VARIANT - outlined2 */
.DRP_outlined2 .DateInput {
  width: 90px;
}
.DRP_outlined2 .DateRangePickerInput_calendarIcon {
  /* line-height: 1.125rem; */
  padding: 0px 0px 2px 10px;
  margin: 0px;
}
.DRP_outlined2 .DateRangePickerInput_arrow {
  /* line-height: 1.125rem; */
  padding: 0px 0px 2px 0px;
  margin: 0px;
}
.DRP_outlined2 .DateInput_input {
  color: #52575C;
  font-size: 0.75rem;
  font-weight: 500;
  height: 32px;
  letter-spacing: 0.00625rem;
}
.DRP_outlined2 .MuiSvgIcon-root {
  fill: #A0A4A8;
}
.DRP_outlined2 .DateRangePickerInput__withBorder {
  border-radius: 4px;
  border: 1px solid #DBDDE0;
  height: 32px;
  background-color: transparent;
}
.DRP_outlined2 .DateRangePickerInput__withBorder:hover {
  border: 1px solid #007BA7;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.DRP_outlined2 .DateRangePickerInput__withBorder:hover .MuiSvgIcon-root {
  fill: #007BA7;
}
.DRP_outlined2 .DateRangePickerInput__withBorder:hover .DateInput_input {
  color: #007BA7;
}
.DRP_outlined2 .DateInput_input__focused {
  border-bottom: 2px solid #007BA7;
}

/* VARIANT - form */
.DRP_form .DateInput {
  width: 104px;
}
.DRP_form .DateInput_input {
  color: #25282B;
  font-size: 1rem;
  font-weight: 400;
  padding-top: 6px;
  padding-bottom: 7px;
  padding-left: 0px;
  line-height: 19px;
}
.DRP_form .DateRangePickerInput_arrow {
  padding-right: 16px;
}
.DRP_form .DateRangePickerInput_arrow_svg {
  fill: #52575C;
  padding-bottom: 6px;
}
.DRP_form .DateRangePickerInput_calendarIcon {
  /* padding: 0px;
  margin: 0px;
  padding-bottom: 4px;
  padding-right: 4px; */
  display: none;
}
.DRP_form .DateRangePickerInput_calendarIcon_svg {
  fill: #007BA7;
}
.DRP_form .DateRangePickerInput__withBorder {
  border: none;
  border-bottom: 1px solid #A0A4A8;
  border-radius: 0px;
  background: none;
  background-color: transparent;
  height: 32px;
}
.DRP_form .DateRangePickerInput__withBorder:hover {
  border-bottom: 1px solid #007BA7;
}
.DRP_form .DateRangePickerInput__withBorder:focused {
  border-bottom: 1px solid #007BA7;
}
.DRP_form .DateInput_input__focused {
  background-color: rgba(0, 123, 167, 0.06);
  /* border-bottom: 1px solid #007BA7; */
  border-bottom: 2px solid #007BA7;
}
