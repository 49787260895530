@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600);


/* Reboot */
html, body { margin: 0 !important; }

/* Typography */
body {
  font-family: "Montserrat";
}

/*Convertion Ratio sp to rem for html */
$conversionRatioSpToRemWeb: 1/16; //! 0.0625;
$conversionRatioRemToPxWeb: 16.0; 

// Font Weights
$font-weight-light:    300;
$font-weight-regular:  400;
$font-weight-medium:   500;
$font-weight-semibold: 600;

// Scales
@function spToRem($sps){
  @return $sps * $conversionRatioSpToRemWeb;
};
@function remToPx($rems){
  @return $rems * $conversionRatioRemToPxWeb;
};
@function spacing($tracking, $px){
  @return $tracking / $px;
};
@mixin typo($size, $weight, $spacing, $height) {
  font-weight:  $weight;
  font-size: #{spToRem($size)}rem;
  letter-spacing: #{spacing($spacing, remToPx($size))}px;
  line-height: #{spToRem($height)}rem;
}

///////////////////
/// Font Styles ///
///////////////////

.h1        { @include typo(52, $font-weight-light,    0.2, 64); }
.h2        { @include typo(44, $font-weight-medium,   0.0, 54); }
.h3        { @include typo(32, $font-weight-semibold, 0.1, 40); }
.h4        { @include typo(26, $font-weight-medium,   0.2, 32); }
.h5        { @include typo(20, $font-weight-semibold, 0.2, 26); }
.h6        { @include typo(18, $font-weight-medium,   0.2, 24); }
.subtitle1 { @include typo(16, $font-weight-medium,   0.1, 24); }
.subtitle2 { @include typo(14, $font-weight-medium,   0.1, 18); }
.body1     { @include typo(16, $font-weight-regular,  0.1, 24); }
.body2     { @include typo(14, $font-weight-regular,  0.1, 22); }
.small1    { @include typo(12, $font-weight-regular,  0.2, 16); }
.small2    { @include typo(12, $font-weight-regular,  0.2, 16); }
.small3    { @include typo(11, $font-weight-regular,  0.2, 14); }
.button    { @include typo(14, $font-weight-semibold, 0.2, 18); }
.initials  { 
  @include typo(13, $font-weight-semibold, 0.2, 16); 
  text-transform: uppercase;
}

//////////////
/// Colors ///
//////////////

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

$white:     #FFFFFF;
$white-70:  #FFFFFF70;
$white-30:  #FFFFFF30;

$black-100: #25282B;
$black-80:  #52575C;
$black-60:  #A0A4A8;
$black-40:  #CACCCF;
$black-20:  #DBDDE0;
$black-10:  #E8E8E8;
$black-5:   #F9F9FA;

$primary:   #007BA7;

$primary-90:  tint($primary, 90);
$primary-80:  tint($primary, 80);
$primary-70:  tint($primary, 70);
$primary-60:  tint($primary, 60);
$primary-50:  tint($primary, 50);
$primary-40:  tint($primary, 40);
$primary-30:  tint($primary, 30);
$primary-20:  tint($primary, 20);
$primary-10:  tint($primary, 10);
$primary-bg:  shade($primary, 10);




